import logo from "./logo.svg";
import { useEffect, useState } from "react";
import { Form, InputNumber, Popconfirm, Table, Typography, Input, Checkbox, Button, notification, Space  } from 'antd';
import { Menu } from 'antd';
import "./App.css";

import { AuthProvider, RequireAuth } from "react-auth-kit";

import RequestPage from "./RequestPage";
import CitiesPage from "./CitiesPage";
import ImagesPage from "./ImagesPage"
import BlogPage from './BlogPage'
import CatalogCategoryPage from "./CatalogCategoryPage"
import CatalogPage from "./CatalogPage"
import LoginPage from "./LoginPage"
import HashtagsPage from "./HashtagsPage";
import ItemsPage from "./ItemsPage";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import "antd/dist/reset.css";

function App() {
  const [currentRoute, setCurrentRoute] = useState('/');
  const onClick = (e) => {
    console.log('click ', e.key);
    setCurrentRoute(`${e.key}`);
  };

  useEffect(() => {
    setCurrentRoute(window.location.pathname)
  }, [])

  const items = [
    {
      label: (
        <Link to={"/"}>Заявки</Link>

      ),
      key: '/',
    },
    // {
    //   label: (
    //     <Link to={"/blog"}>Blog</Link>
    //   ),
    //   key: '/blog',
    // },
    // {
    //   label: (
    //     <Link to={"/catalog"}>Catalog</Link>
    //   ),
    //   key: '/catalog',
    // },
    // {
    //   label: (
    //     <Link to={"/catalog-category"}>Catalog Category</Link>
    //   ),
    //   key: '/catalog-category',
    // },

  ]

  return (
    <>
    <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    cookieSecure={false}
    >
    <BrowserRouter>
      <Menu onClick={onClick} selectedKeys={currentRoute} mode="horizontal" items={items} />
        <Routes>
          <Route index path="/" element={<RequireAuth loginPath={"/login"}><RequestPage /></RequireAuth>} />
          {/* <Route index path="/blog" element={<RequireAuth loginPath={"/login"}><BlogPage /></RequireAuth>} /> */}
          {/* <Route index path="/catalog" element={<RequireAuth loginPath={"/login"}><CatalogPage /></RequireAuth>} /> */}
          {/* <Route index path="/catalog-category" element={<RequireAuth loginPath={"/login"}><CatalogCategoryPage /></RequireAuth>} /> */}
          {/* CatalogCategoryPage */}

          {/* <Route index path="/hashtags" element={<RequireAuth loginPath={"/login"}><HashtagsPage /></RequireAuth>} />
          <Route index path="/images" element={<RequireAuth loginPath={"/login"}><ImagesPage /></RequireAuth>} /> */}

          <Route index path="/login" element={<LoginPage />} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
    

    </>
  );
}

export default App;
