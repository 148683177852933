import { useEffect, useState } from "react";
import axios from "axios"
import { Form, InputNumber, Popconfirm, Table, Typography, Input, Checkbox, Button, notification, Space  } from 'antd';
import {API_URL} from './config'
import {
    DeleteOutlined
  } from '@ant-design/icons';
import { useSignIn } from "react-auth-kit";



import { useNavigate } from "react-router-dom"



function LoginPage() {
  const signIn = useSignIn()
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log('Success:', values);
    axios.post(`${API_URL}/api/v1/auth/signin`, values).then(res => {
      console.log(res.data)
      
      signIn({
        token: res.data.access_token,
        expiresIn: 10080,
        tokenType: "Bearer",
        authState: {
          token: res.data.access_token
        }
      })
      navigate("/");
    })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
    return (
      <div style={{margin: 20}}>
      <>
      <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Пошта"
      name="email"
      rules={[
        {
          required: false,
          message: 'Please input your username!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Пароль"
      name="password"
      rules={[
        {
          required: false,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>



    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Вхід
      </Button>
    </Form.Item>
  </Form>
      </>
      </div>
  
  
    );
}

export default LoginPage;