import { useEffect, useState } from "react";
import axios from "axios"
import { Form, InputNumber, Popconfirm, Table, Typography, Input, Checkbox, Button, notification, Space , Tooltip, Tag, Upload,Modal } from 'antd';
import {useAuthUser} from 'react-auth-kit'
import {API_URL} from './config'

import {
    DeleteOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    PlusOutlined
  } from '@ant-design/icons';
const { Title } = Typography;



  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

function BlogPage() {
 
  const auth = useAuthUser()
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
      api[type]({
        message: title,
        description:
        message,
      });
    };

    const getData = async () => {
        const res = await axios.post(`${API_URL}/api/v1/blog/getArticles`, {headers: {'x-access-token': `${auth().token}`}})
        console.log(res.data)
        setData(res.data.blogs)       
      }
    
    
      useEffect(() => {
        getData()
      console.log(API_URL)
      }, []);
    
      const [form] = Form.useForm();
      const [data, setData] = useState([]);
      const [dataToUpd, setDataToUpd] = useState({})
      const [editingKey, setEditingKey] = useState('');
      const isEditing = (record) => record._id === editingKey;
      const edit = (record) => {
        form.setFieldsValue({
          ...record,
        });
        setEditingKey(record._id);
      };
      const cancel = () => {
        setEditingKey('');
      };
      const save = async (key) => {
        try {
          const row = await form.validateFields();
          
          const newData = [...data];
          const index = newData.findIndex((item) => key === item._id);
    
          const newRow = row
          const oldRow = data[index]
          console.log(row)
          console.log(data[index])
    
          let propertyToUpd = {}
    
          Object.keys(newRow).forEach(property => {
            if(oldRow.hasOwnProperty(property)){
              if(newRow[property] !== oldRow[property]){
                console.log(property)
                propertyToUpd = {...propertyToUpd, [property]: newRow[property]}
                // setDataToUpd({...dataToUpd, [oldRow._id]: {[property]: newRow[property]}})
              }
            }
          })
    
          setDataToUpd({...dataToUpd, [oldRow._id]: {...dataToUpd[oldRow._id] ,...propertyToUpd}})
    
          
    
          
    
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setData(newData);
            setEditingKey('');
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
          }
    
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
      };

      const [currentIdEdit, setCurrentIdEdit] = useState("");
      const columns = [
        {
            // ellipsis: true,
          title: 'ID ended',
          dataIndex: '_id',
          width: 30,
          render: (_, { _id }) => (
            <Tooltip placement="topLeft" title={_id}>
              {String(_id).slice(-7)}
            </Tooltip>
          ),
        },
        {
          title: 'Hashtags',
          dataIndex: 'hashtags',
          width: 100,
          render: (_, { hashtags, _id }) => (
            <>
                  <Button
      type="primary"
      onClick={() => {
        setCurrentIdEdit(_id)
        setHashtagsModalStatus(true);
      }}
    >
      images
    </Button>
            </>
          ),
        },
        {
            // ellipsis: true,
          title: 'reading_time',
          dataIndex: 'reading_time',
          width: 100,
          editable: true,
        },
        {
          title: 'name',
          dataIndex: 'name',
          width: 100,
          editable: true,
        },
        {
          title: 'desc',
          dataIndex: 'desc',
          width: 100,
          editable: true,
        },


        {
          title: 'operation',
          width: 80,
          dataIndex: 'operation',
          fixed: 'right',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record._id)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </Typography.Link>
              <Button style={{
              marginLeft: '20px',
            }} disabled={ !dataToUpd.hasOwnProperty(record._id)} type={ dataToUpd.hasOwnProperty(record._id) ? "primary" : "default"} onClick={() => {

              axios.post(`${API_URL}/api/v1/blog/updateArticle/${record._id}`, dataToUpd[record._id], {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                openNotificationWithIcon('success', "Data successed Updated", "")
                let cloneDataToUpd = JSON.parse(JSON.stringify(dataToUpd))
                delete cloneDataToUpd[record._id]
                setDataToUpd(cloneDataToUpd)
    
              })
            }}>Update</Button>
    
    
    
            <Popconfirm title={`Delete ${record.name}?`} onConfirm={()=> {
              
              axios.post(`${API_URL}/api/v1/blog/removeArticle/${record._id}`, {}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                openNotificationWithIcon('success', "Acticle successed deleted", "")
                setData(data.filter(d => d._id !== record._id))
    
                // setDataToUpd()
              })
              console.log("uod", dataToUpd[record.key] , record.key)
            }}>
                  <Button style={{
              marginLeft: '20px',
            }} type="default" ><DeleteOutlined/></Button>
                </Popconfirm>
    
           
    
              </div>
    
            );
          },
        },
      ];
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
      const [imagesBase64, setImagesBase64] = useState("");


      const handleFileUpload = async (e) => {
        const files = e.target.files;
        const filesBase64 = []

        Object.values(files).forEach(async(file) => {
          const base64 = await convertToBase64(file)
          filesBase64.push({thumbUrl: base64})
        })

        setImagesBase64(filesBase64);
      };




      const [formAdd] = Form.useForm();
      const numberFileds = ["horizontalOrder" , "verticalOrder"]
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: numberFileds.includes(col.dataIndex) ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });


      const [hashtagsModalStatus, setHashtagsModalStatus] = useState(false);

      const HashtagsModal = ({ open, onCreate, onCancel }) => {
        const [form] = Form.useForm();
      const [imagesBase64Modal, setImagesBase64Modal] = useState("");

        const handleFileUploadModal = async (e) => {
          const files = e.target.files;
          const filesBase64 = []
  
          Object.values(files).forEach(async(file) => {
            const base64 = await convertToBase64(file)
            filesBase64.push({thumbUrl: base64})
          })
  
          setImagesBase64Modal(filesBase64);
        };

        return (
          <Modal
            open={open}
            title="Edit images"
            okText={null}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={''}
            footer={null}
          >
            <div className="list-images">
              {
                data.filter(d => d._id === currentIdEdit)[0]?.images.map(image => <div>
                  <img src={`https://my-testf22.nyc3.cdn.digitaloceanspaces.com/blog/${image.uri}`}/>
                  <Popconfirm title="are u sure ?" onConfirm={() => {
            console.log(imagesBase64)
          axios.post(`${API_URL}/api/v1/blog/articleRemoveImage`, {imagesIds: [image._id]}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Image successed removed", "")
            setData(data.map(d => {
              console.log(d)
              console.log(currentIdEdit)
              if(d._id === currentIdEdit){
                return {...d, images: d.images.filter(ff => ff._id !== image._id)}
              }else{
                return d
              }
            }))
          })
          }}>
                  <Button type="primary">Remove</Button>
                </Popconfirm>
                 
                </div>)
              }
              
            </div>
            <form>
            <Title style={{marginTop: 40}} level={4}>Add images</Title>
        <input
          type="file"
          label="Image"
          name="myFile"
          accept=".jpeg, .png, .jpg"
          multiple
          onChange={(e) => {
            // e.preventDefault()
            handleFileUploadModal(e)
          }}
        />

        
      </form>
      <Button style={{marginTop: 20}}  onClick={() => {
          axios.post(`${API_URL}/api/v1/blog/articleAddImage`, {blog_id: currentIdEdit, images: imagesBase64Modal.map(item => ({base64: item.thumbUrl}))}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Image successed added", "")

          })
          }} type="primary">Add images</Button>
          </Modal>
        );
      };
      const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
      };
    return  <div style={{margin: 20}}>
    <>
    <HashtagsModal
        open={hashtagsModalStatus}
        onCreate={null}
        onCancel={() => {
          setHashtagsModalStatus(false);
        }}
      />


        <Form form={form} component={false}>
      <h1>Blog</h1>
      {contextHolder}
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        scroll={{x: 1200,}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>

    <h2>Create request</h2>

    <div>
      <form>
        <div>Images</div>
        <input
          type="file"
          label="Image"
          name="myFile"
          accept=".jpeg, .png, .jpg"
          multiple
          onChange={(e) => handleFileUpload(e)}
        />

        
      </form>
    </div>

    <Form

      layout={"vertical"}
      form={formAdd}
      initialValues={{
        layout: "vertical",
        cityID: "641871ac78932068be67d75d"
      }}

      style={{
        maxWidth: 600,
      }}
    >

      <Form.Item name="name" label="name">
        <Input placeholder="name" />
      </Form.Item>

      <Form.Item name="reading_time" label="reading_time">
        <Input placeholder="reading_time" />
      </Form.Item>


      <Form.Item name="desc" label="desc">
        <Input placeholder="Super long desc" />
      </Form.Item>
      
      <Form.Item>
        <Button onClick={() => {
          let {
            reading_time,
            name,
            desc,
            } = formAdd.getFieldsValue()

            console.log(imagesBase64)
            
          axios.post(`${API_URL}/api/v1/blog/createArticle`, {
            images: imagesBase64.map(item => ({base64: item.thumbUrl})),
            reading_time,
            name,
            desc,
          }, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Article successed created", "")
            formAdd.resetFields()

          })
         
          }} type="primary">Submit</Button>
      </Form.Item>
    </Form>
    
    </>
    </div>

}

export default BlogPage;