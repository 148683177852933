import { useEffect, useState } from "react";
import axios from "axios"
import { Form, InputNumber, Popconfirm, Table, Typography, Input, Checkbox, Button, notification, Space , Tooltip, Tag, Upload,Modal } from 'antd';
import {useAuthUser} from 'react-auth-kit'
import {API_URL} from './config'



import {
    DeleteOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    PlusOutlined
  } from '@ant-design/icons';
const { Title } = Typography;
const { CheckableTag } = Tag;



  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

function CatalogItems({currentIdItems}) {
 
  const auth = useAuthUser()
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
      api[type]({
        message: title,
        description:
        message,
      });
    };

    const getData = async () => {
      if(currentIdItems){
        const res = await axios.post(`${API_URL}/api/v1/catalog/getCatalogItems/${currentIdItems}`, )
        console.log(res.data.items)
        setData(res.data.items)    
      }
     
      }

      const getCategoriesData = async () => {
          const res = await axios.post(`${API_URL}/api/v1/catalog/getCatalogCategories`, )
          setCategories(res.data)    
       
        }
    
        useEffect(() => {
          getCategoriesData()
        }, [])
    
      useEffect(() => {
        getData()
      }, [currentIdItems]);
    
      const [form] = Form.useForm();
      const [data, setData] = useState([]);
      const [categories, setCategories] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);
      const [dataToUpd, setDataToUpd] = useState({})
      const [editingKey, setEditingKey] = useState('');
      const isEditing = (record) => record._id === editingKey;
      const edit = (record) => {
        form.setFieldsValue({
          ...record,
        });
        setEditingKey(record._id);
      };
      const cancel = () => {
        setEditingKey('');
      };
      const save = async (key) => {
        try {
          const row = await form.validateFields();
          
          const newData = [...data];
          const index = newData.findIndex((item) => key === item._id);
    
          const newRow = row
          const oldRow = data[index]
          console.log(row)
          console.log(data[index])
    
          let propertyToUpd = {}
    
          Object.keys(newRow).forEach(property => {
            if(oldRow.hasOwnProperty(property)){
              if(newRow[property] !== oldRow[property]){
                console.log(property)
                propertyToUpd = {...propertyToUpd, [property]: newRow[property]}
                // setDataToUpd({...dataToUpd, [oldRow._id]: {[property]: newRow[property]}})
              }
            }
          })
    
          setDataToUpd({...dataToUpd, [oldRow._id]: {...dataToUpd[oldRow._id] ,...propertyToUpd}})
    
          
    
          
    
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setData(newData);
            setEditingKey('');
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
          }
    
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
      };

      const [currentIdEdit, setCurrentIdEdit] = useState("");
      const columns = [
        {
            // ellipsis: true,
          title: 'ID ended',
          dataIndex: '_id',
          width: 30,
          render: (_, { _id }) => (
            <Tooltip placement="topLeft" title={_id}>
              {String(_id).slice(-7)}
            </Tooltip>
          ),
        },
        {
          title: 'Categories',
          dataIndex: 'hashtags',
          width: 100,
          render: (_, { hashtags, _id }) => (
            <>
                  <Button
      type="primary"
      onClick={() => {
        setCurrentIdEdit(_id)
        setCategoriesModalStatus(true);
      }}
    >
      categories
    </Button>
            </>
          ),
        },
        {
          title: 'Tags',
          dataIndex: 'hashtags',
          width: 100,
          render: (_, { hashtags, _id }) => (
            <>
                  <Button
      type="primary"
      onClick={() => {
        setCurrentIdEdit(_id)
        setTagsModalStatus(true);
      }}
    >
      tags
    </Button>
            </>
          ),
        },
        {
          title: 'Colors',
          dataIndex: 'colors',
          width: 100,
          render: (_, { hashtags, _id }) => (
            <>
                  <Button
      type="primary"
      onClick={() => {
        setCurrentIdEdit(_id)
        setColorsModalStatus(true);
      }}
    >
      colors
    </Button>
            </>
          ),
        },
        {
          title: 'Hashtags',
          dataIndex: 'hashtags',
          width: 100,
          render: (_, { hashtags, _id }) => (
            <>
                  <Button
      type="primary"
      onClick={() => {
        setCurrentIdEdit(_id)
        setHashtagsModalStatus(true);
      }}
    >
      images
    </Button>
            </>
          ),
        },
        {
          title: 'name',
          dataIndex: 'name',
          width: 100,
          editable: true,
        },
        {
          title: 'application',
          dataIndex: 'application',
          width: 100,
          editable: true,
        },

        {
          title: 'color',
          dataIndex: 'color',
          width: 100,
          editable: true,
        },


        {
          title: 'color_make',
          dataIndex: 'color_make',
          width: 100,
          editable: true,
        },

        {
          title: 'erasure',
          dataIndex: 'erasure',
          width: 100,
          editable: true,
        },

        {
          title: 'material',
          dataIndex: 'material',
          width: 100,
          editable: true,
        },

        {
          title: 'price',
          dataIndex: 'price',
          width: 100,
          editable: true,
        },

        {
          title: 'size',
          dataIndex: 'size',
          width: 100,
          editable: true,
        },
        
        {
          title: 'size_rejection',
          dataIndex: 'size_rejection',
          width: 100,
          editable: true,
        },

        {
          title: 'surface',
          dataIndex: 'surface',
          width: 100,
          editable: true,
        },

        {
          title: 'weight',
          dataIndex: 'weight',
          width: 100,
          editable: true,
        },


        {
          title: 'operation',
          width: 80,
          dataIndex: 'operation',
          fixed: 'right',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record._id)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </Typography.Link>
              <Button style={{
              marginLeft: '20px',
            }} disabled={ !dataToUpd.hasOwnProperty(record._id)} type={ dataToUpd.hasOwnProperty(record._id) ? "primary" : "default"} onClick={() => {

              axios.post(`${API_URL}/api/v1/catalog/updateCatalogItem/${record._id}`, dataToUpd[record._id], {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                openNotificationWithIcon('success', "Data successed Updated", "")
                let cloneDataToUpd = JSON.parse(JSON.stringify(dataToUpd))
                delete cloneDataToUpd[record._id]
                setDataToUpd(cloneDataToUpd)
    
              })
            }}>Update</Button>
    
    
    
            <Popconfirm title={`Delete ${record.name}?`} onConfirm={()=> {
              
              axios.post(`${API_URL}/api/v1/blog/removeArticle/${record._id}`, {}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                openNotificationWithIcon('success', "Acticle successed deleted", "")
                setData(data.filter(d => d._id !== record._id))
    
                // setDataToUpd()
              })
              console.log("uod", dataToUpd[record.key] , record.key)
            }}>
                  <Button style={{
              marginLeft: '20px',
            }} type="default" ><DeleteOutlined/></Button>
                </Popconfirm>
    
           
    
              </div>
    
            );
          },
        },
      ];
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
      const [imagesBase64, setImagesBase64] = useState("");


      const handleFileUpload = async (e) => {
        const files = e.target.files;
        const filesBase64 = []

        Object.values(files).forEach(async(file) => {
          const base64 = await convertToBase64(file)
          filesBase64.push({thumbUrl: base64})
        })

        setImagesBase64(filesBase64);
      };




      const [formAdd] = Form.useForm();
      const numberFileds = ["horizontalOrder" , "verticalOrder"]
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: numberFileds.includes(col.dataIndex) ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      const [categoriesModalStatus, setCategoriesModalStatus] = useState(false);
      const CategoriesModal = ({ open, onCreate, onCancel }) => {
        const [form] = Form.useForm();
        const [editSelectedCategories, setEditSelectedCategories] = useState([])
        const [editCategories, setEditCategories] = useState({})
        const [editCategoriesNames, setEditCategoriesNames] = useState({})
        useEffect(() => {

          let cat = {}
          categories.forEach(c => {
            cat[c._id] = {
              _id: c._id,
              name: c.name
            }
          })


          let catNames = {}
          categories.forEach(c => {
            catNames[c.name] = {
              _id: c._id,
              name: c.name
            }
          })
          setEditCategoriesNames(catNames)
          setEditCategories(cat)
          setEditSelectedCategories(data.filter(d => d._id === currentIdEdit)[0]?.categories.map(catId => cat[catId]?.name) || [])


          
        }, [currentIdEdit])

        const handleChangeModal = (tag, checked) => {
          const nextSelectedTags = checked
            ? [...editSelectedCategories, tag]
            : editSelectedCategories.filter((t) => t !== tag);
          console.log('You are interested in: ', nextSelectedTags);
          setEditSelectedCategories(nextSelectedTags);
        };

        useEffect(() => {
          console.log(editCategories)
        }, [editCategories])
        return (
          <Modal
            open={open}
            title="Edit hashtags"
            okText="Save"
            cancelText="Cancel"
            onCancel={onCancel}
            footer={null}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onCreate(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
            >

<>
      <span style={{ marginRight: 8 }}>Categories:</span>
      <Space size={[0, 8]} wrap>
        {categories.map(i => i.name).map((tag) => (
          <CheckableTag
            key={tag}
            checked={editSelectedCategories.includes(tag)}
            onChange={(checked) => handleChangeModal(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Space>
    </>
    <Form.Item>
        <Button onClick={() => {
          let {
            hashtags,
            } = form.getFieldsValue()
          axios.post(`${API_URL}/api/v1/catalog/updateCatalogItem/${currentIdEdit}`, {categories: editSelectedCategories.length ? editSelectedCategories.map(catName => editCategoriesNames[catName]?._id)  : []}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Catalog successed Updated", "")

          })
           
         
          }} type="primary">Update</Button>
      </Form.Item>

            </Form>
          </Modal>
        );
      };

      const [colorsModalStatus, setColorsModalStatus] = useState(false);
      const ColorsModal = ({ open, onCreate, onCancel, hashtags }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            open={open}
            title="Edit colors"
            okText="Save"
            cancelText="Cancel"
            onCancel={onCancel}
            footer={null}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onCreate(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                hashtags: data.filter(d => d._id === currentIdEdit)[0]?.colors ? data.filter(d => d._id === currentIdEdit)[0]?.colors.map(item => ({hashtag: item})) : []
              }}
            >

<Form.List name="hashtags">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'hashtag']}
              >
                <Input placeholder="color" />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Color
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
    <Form.Item>
        <Button onClick={() => {
          let {
            hashtags,
            } = form.getFieldsValue()

          axios.post(`${API_URL}/api/v1/catalog/updateCatalogItem/${currentIdEdit}`, {colors: hashtags ? hashtags.map(h => h.hashtag) : []}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Catalog successed Updated", "")

          })
           
         
          }} type="primary">Update</Button>
      </Form.Item>

            </Form>
          </Modal>
        );
      };

      const [tagsModalStatus, setTagsModalStatus] = useState(false);
      const TagsModal = ({ open, onCreate, onCancel, hashtags }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            open={open}
            title="Edit hashtags"
            okText="Save"
            cancelText="Cancel"
            onCancel={onCancel}
            footer={null}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onCreate(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                hashtags: data.filter(d => d._id === currentIdEdit)[0]?.tags.map(item => ({hashtag: item}))
              }}
            >

<Form.List name="hashtags">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'hashtag']}
              >
                <Input placeholder="hashtag" />
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Hashtag
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
    <Form.Item>
        <Button onClick={() => {
          let {
            hashtags,
            } = form.getFieldsValue()

          axios.post(`${API_URL}/api/v1/catalog/updateCatalogItem/${currentIdEdit}`, {tags: hashtags ? hashtags.map(h => h.hashtag) : []}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Catalog successed Updated", "")

          })
           
         
          }} type="primary">Update</Button>
      </Form.Item>

            </Form>
          </Modal>
        );
      };


      const [hashtagsModalStatus, setHashtagsModalStatus] = useState(false);

      const HashtagsModal = ({ open, onCreate, onCancel }) => {
        const [form] = Form.useForm();
      const [imagesBase64Modal, setImagesBase64Modal] = useState("");

        const handleFileUploadModal = async (e) => {
          const files = e.target.files;
          const filesBase64 = []
  
          Object.values(files).forEach(async(file) => {
            const base64 = await convertToBase64(file)
            filesBase64.push({thumbUrl: base64})
          })
  
          setImagesBase64Modal(filesBase64);
        };

        return (
          <Modal
            open={open}
            title="Edit images"
            okText={null}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={''}
            footer={null}
          >
            <div className="list-images">
              {
                data.filter(d => d._id === currentIdEdit)[0]?.images.map(image => <div>
                  <img src={`https://my-testf22.nyc3.cdn.digitaloceanspaces.com/catalogItemImages/${image.uri}`}/>
                  <Popconfirm title="are u sure ?" onConfirm={() => {
            console.log(imagesBase64)
          axios.post(`${API_URL}/api/v1/catalog/catalogItemRemoveImage`, {imagesIds: [image._id]}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Image successed removed", "")
            setData(data.map(d => {
              console.log(d)
              console.log(currentIdEdit)
              if(d._id === currentIdEdit){
                return {...d, images: d.images.filter(ff => ff._id !== image._id)}
              }else{
                return d
              }
            }))
          })
          }}>
                  <Button type="primary">Remove</Button>
                </Popconfirm>
                 
                </div>)
              }
              
            </div>
            <form>
            <Title style={{marginTop: 40}} level={4}>Add images</Title>
        <input
          type="file"
          label="Image"
          name="myFile"
          accept=".jpeg, .png, .jpg"
          multiple
          onChange={(e) => {
            // e.preventDefault()
            handleFileUploadModal(e)
          }}
        />

        
      </form>
      <Button style={{marginTop: 20}}  onClick={() => {
          axios.post(`${API_URL}/api/v1/catalog/catalogItemAddImage/${currentIdEdit}`, {catalog_id: currentIdItems, images: imagesBase64Modal.map(item => ({base64: item.thumbUrl}))}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Image successed added", "")

          })
          }} type="primary">Add images</Button>
          </Modal>
        );
      };
      const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
      };
      const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
          ? [...selectedCategories, tag]
          : selectedCategories.filter((t) => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        setSelectedCategories(nextSelectedTags);
      };
    return  <div style={{margin: 20}}>
    <>
    <HashtagsModal
        open={hashtagsModalStatus}
        onCreate={null}
        onCancel={() => {
          setHashtagsModalStatus(false);
        }}
      />
<ColorsModal
        open={colorsModalStatus}
        onCreate={null}
        onCancel={() => {
          setColorsModalStatus(false);
        }}
      />
<TagsModal
        open={tagsModalStatus}
        onCreate={null}
        onCancel={() => {
          setTagsModalStatus(false);
        }}
      />
<CategoriesModal
        open={categoriesModalStatus}
        onCreate={null}
        onCancel={() => {
          setCategoriesModalStatus(false);
        }}
      />


        <Form form={form} component={false}>
      <h1>Items</h1>
      {contextHolder}
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        scroll={{x: 4200,}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>

    <h2>Create catalog item</h2>

    <div>
      
    </div>
    <>
      <span style={{ marginRight: 8 }}>Categories:</span>
      <Space size={[0, 8]} wrap>
        {categories.map(i => i.name).map((tag) => (
          <CheckableTag
            key={tag}
            checked={selectedCategories.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Space>
    </>
    <div>
      <form>
        <div>Images</div>
        <input
          type="file"
          label="Image"
          name="myFile"
          accept=".jpeg, .png, .jpg"
          multiple
          onChange={(e) => handleFileUpload(e)}
        />

        
      </form>
    </div>

    <Form

      layout={"vertical"}
      form={formAdd}
      initialValues={{
        layout: "vertical",
        cityID: "641871ac78932068be67d75d"
      }}

      style={{
        maxWidth: 600,
      }}
    >

    {/* categories */}
    <div style={{marginTop: 20}}>
    <Form.List name="tags">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, 'hashtag']}
                >
                  <Input placeholder="tag" />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add tag
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
    

      <Form.Item name="name" label="name">
        <Input placeholder="name" />
      </Form.Item>

      <Form.Item name="price" label="price">
        <Input placeholder="price" />
      </Form.Item>

      <Form.Item name="color" label="color">
        <Input placeholder="color" />
      </Form.Item>

      <Form.Item name="color_make" label="color_make">
        <Input placeholder="color_make" />
      </Form.Item>

      <Form.Item name="material" label="material">
        <Input placeholder="material" />
      </Form.Item>


      <Form.Item name="surface" label="surface">
        <Input placeholder="surface" />
      </Form.Item>

      <Form.Item name="application" label="application">
        <Input placeholder="application" />
      </Form.Item>

      <Form.Item name="size" label="size">
        <Input placeholder="size" />
      </Form.Item>

      <Form.Item name="size_rejection" label="size_rejection">
        <Input placeholder="size_rejection" />
      </Form.Item>

      <Form.Item name="height" label="height">
        <Input placeholder="height" />
      </Form.Item>
      

      <Form.Item name="weight" label="weight">
        <Input placeholder="weight" />
      </Form.Item>

      <Form.Item name="erasure" label="erasure">
        <Input placeholder="erasure" />
      </Form.Item>
      
      <Form.Item>
        <Button onClick={() => {
          let {
            name,
            tags,
            price,
            color,
            color_make,
            material,
            surface,
            application,
            size,
            size_rejection,
            height,
            weight,
            erasure
            } = formAdd.getFieldsValue()

            console.log(imagesBase64)
            
          axios.post(`${API_URL}/api/v1/catalog/createCatalogItem`, {
            categories: categories.filter(cat => !selectedCategories.includes(cat)).map(c => c._id),
            images: imagesBase64.map(item => ({base64: item.thumbUrl})),
            catalog_id: currentIdItems,
            name,
            tags: tags ? tags.map(h => h.hashtag) : [],
            price: Number(price),
            color,
            color_make,
            material,
            surface,
            application,
            size,
            size_rejection: Number(size_rejection),
            height: Number(height),
            weight: Number(weight),
            erasure: Number(erasure)
          }, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Article successed created", "")
            // formAdd.resetFields()

          })
         
          }} type="primary">Submit</Button>
      </Form.Item>
    </Form>
    
    </>
    </div>

}

export default CatalogItems;