import { useEffect, useState } from "react";
import axios from "axios"
import { Form, InputNumber, Popconfirm, Table, Typography, Input, Checkbox, Button, notification, Space , Tooltip, Tag, Upload,Modal } from 'antd';
import {useAuthUser} from 'react-auth-kit'
import {API_URL} from './config'

import {
    DeleteOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    PlusOutlined
  } from '@ant-design/icons';



  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

function RequestPage() {
 
  const auth = useAuthUser()
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
      api[type]({
        message: title,
        description:
        message,
      });
    };

    const getData = async () => {
        const res = await axios.get(`${API_URL}/api/v1/landing/getRequests`, {headers: {'x-access-token': `${auth().token}`}})
        console.log(res.data)
        setData(res.data)       
      }
    
    
      useEffect(() => {
        getData()
      console.log(API_URL)
      }, []);

    
      const [form] = Form.useForm();
      const [data, setData] = useState([]);
      const [dataToUpd, setDataToUpd] = useState({})
      const [editingKey, setEditingKey] = useState('');
      const isEditing = (record) => record.key === editingKey;
      const edit = (record) => {
        form.setFieldsValue({
          ...record,
        });
        setEditingKey(record.key);
      };
      const cancel = () => {
        setEditingKey('');
      };
      const save = async (key) => {
        try {
          const row = await form.validateFields();
          
          const newData = [...data];
          const index = newData.findIndex((item) => key === item.key);
    
          const newRow = row
          const oldRow = data[index]
          console.log(row)
          console.log(data[index])
    
          let propertyToUpd = {}
    
          Object.keys(newRow).forEach(property => {
            if(oldRow.hasOwnProperty(property)){
              if(newRow[property] !== oldRow[property]){
                console.log(property)
                propertyToUpd = {...propertyToUpd, [property]: newRow[property]}
                // setDataToUpd({...dataToUpd, [oldRow._id]: {[property]: newRow[property]}})
              }
            }
          })
    
          setDataToUpd({...dataToUpd, [oldRow._id]: {...dataToUpd[oldRow._id] ,...propertyToUpd}})
    
          
    
          
    
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setData(newData);
            setEditingKey('');
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
          }
    
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
      };

      // social links edit modal








      const [timeworkModalStatus, setTimeworkModalStatus] = useState(false);
      const [currentTimeWorkEdit, setCurrentTimeWorkEdit] = useState([]);

      const [hashtagsModalStatus, setHashtagsModalStatus] = useState(false);
      const [currentHashtagsEdit, setCurrentHashtagsEdit] = useState([]);

      const [socialLinksModalStatus, setSocialLinksModalStatus] = useState(false);
      const [currentSocialLinksEdit, setCurrentSocialLinksEdit] = useState([]);
      const [currentIdEdit, setCurrentIdEdit] = useState("");

      function formatDate(date) {
        let datePart = [
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear()
        ].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("/");
        let timePart = [
          date.getHours(),
          date.getMinutes(),
        ].map((n, i) => n.toString().padStart(2, "0")).join(":");
        return datePart + " " + timePart;
      }
      const columns = [
        {
            // ellipsis: true,
          title: 'Дата',
          dataIndex: '_id',
          width: 100,
          render: (_, { created_at }) => {
            const d = new Date(created_at)
        
            return (
              <Tooltip placement="topLeft">
                {`${formatDate(d)}`}
              </Tooltip>
            )
          },
        },
        {
            title: 'Ім\'я',
            dataIndex: 'name',
            width: 200,
          },
        {
            // ellipsis: true,
          title: 'Телефон',
          dataIndex: 'phone',
          width: 200,
          editable: true,
        },
        {
          // ellipsis: true,
        title: 'Пошта',
        dataIndex: 'email',
        width: 200,
        editable: true,
      },

        {
          // ellipsis: true,
        title: 'Час звінку',
        dataIndex: 'timeCall',
        width: 200,
        editable: true,
      },



        {
          title: 'Прочитано',
          dataIndex: 'is_approved',
          width: 200,
          render: (_, { is_approved, _id }) => (
            <>
            <Checkbox checked={is_approved} onChange={e => {
              setDataToUpd({...dataToUpd, [_id]: {...dataToUpd[_id], is_approved: e.target.checked }})
              console.log(dataToUpd)
              setData(data.map(d => ({...d, is_approved:_id === d._id ? !d.is_approved : d.is_approved})))
              }}></Checkbox>
            </>
          ),
        },
        {
          title: 'Операції',
          width: 200,
          dataIndex: 'operation',
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (<div></div>) : (
              <div style={{display: 'flex'}}>

              <Button style={{
            }} disabled={ !dataToUpd.hasOwnProperty(record._id)} type={ dataToUpd.hasOwnProperty(record._id) ? "primary" : "default"} onClick={() => {

              axios.post(`${API_URL}/api/v1/landing/updateRequest/${record._id}`, dataToUpd[record._id], {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                openNotificationWithIcon('success', "Data successed Updated", "")
                let cloneDataToUpd = JSON.parse(JSON.stringify(dataToUpd))
                delete cloneDataToUpd[record._id]
                setDataToUpd(cloneDataToUpd)

    
                // setDataToUpd()
              })
              console.log("uod", dataToUpd[record.key] , record.key)
            }}>Оновити</Button>
    
    
    
            <Popconfirm title={`Видалити ${record.name}?`} onConfirm={()=> {
              
              axios.post(`${API_URL}/api/v1/landing/removeRequest/${record._id}`, {}, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
                setData(data.filter(d => d._id !== record._id))
                openNotificationWithIcon('success', "request successed deleted", "")
    
                // setDataToUpd()
              })
            }}>
                  <Button style={{
              marginLeft: '20px',
            }} type="default" ><DeleteOutlined/></Button>
                </Popconfirm>
    
           
    
              </div>
    
            );
          },
        },
      ];
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
      const [imagesBase64, setImagesBase64] = useState("");

      const handleFileUpload = async (e) => {
        const files = e.target.files;
        const filesBase64 = []

        Object.values(files).forEach(async(file) => {
          const base64 = await convertToBase64(file)
          filesBase64.push({thumbUrl: base64})
        })

        setImagesBase64(filesBase64);
      };

      
      const [isRegularWorkActive, setIsRegularWorkActive] = useState(false)
      const [isSaturdayWorkActive, setIsSaturdayWorkActive] = useState(false)
      const [isSundayWorkActive, setIsSundayWorkActive] = useState(false)
      const [formAdd] = Form.useForm();
      const numberFileds = ["horizontalOrder" , "verticalOrder"]
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: numberFileds.includes(col.dataIndex) ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });
      const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
      };
    return  <div style={{margin: 20}}>
    <>



        <Form form={form} component={false}>
      {/* <h1>Requests</h1> */}
      {contextHolder}
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        scroll={{x: 1000,}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>

    {/* <h2>Create request</h2>

    <Form

      layout={"vertical"}
      form={formAdd}
      initialValues={{
        layout: "vertical",
      }}

      style={{
        maxWidth: 600,
      }}
    >

      <Form.Item name="email" label="email">
        <Input placeholder="email" />
      </Form.Item>

      <Form.Item name="name" label="name">
        <Input placeholder="name" />
      </Form.Item>


      <Form.Item name="phone" label="phone">
        <Input placeholder="phone" />
      </Form.Item>


      <Form.Item>
        <Button onClick={() => {
          let {
            email,
            name,
            phone
            } = formAdd.getFieldsValue()
            console.log(formAdd.getFieldsValue())
   
          axios.post(`${API_URL}/api/v1/landing/createRequest`, {
            email,
            name,
            phone
          }, {headers: {'x-access-token': `${auth().token}`}}).then(responce => {
            openNotificationWithIcon('success', "Request successed added", "")
            formAdd.resetFields()
          })
         
          }} type="primary">Submit</Button>
      </Form.Item>
    </Form> */}
    
    </>
    </div>

}

export default RequestPage;